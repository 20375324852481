













































































































































































import { CommonListRes, Page, CommonRes } from "@/api/common";
import WorkBoard from "@/components/WorkBoard.vue";
import FloatButton from "@/components/FloatButton.vue";
import TipDialog from "@/components/TipDialog.vue";
import { Toast } from "vant";
import { globalData } from "@/utils/config";
import {
  getMapNames,
  getTaskNames,
  getWorkList,
  GetWorkListReq,
  Work,
  exportTask,
} from "@/api/work";
import { Component, Vue } from "vue-property-decorator";
import { getRobotList } from "@/api/robot";
import { formatDate } from "@/utils/utils";
interface textStatus {
  isSelect: boolean;
  text: string;
  value: string;
}

interface filterList {
  all: boolean;
  title: string;
  icon: string;
  searchText: string;
  list: textStatus[];
}
@Component({
  components: {
    WorkBoard,
    FloatButton,
    TipDialog
  },
})
export default class Index extends Vue {
  private workList: Work[] = [];
  private limit: number = 10;
  private type: number = 0;
private showDialog: boolean = false;
  private refreshing: boolean = false;
  private loading: boolean = false;
  private finished: boolean = false;
  private show: boolean = false;
  private showDateTime: boolean = false;
  private startTime: string = "";
  private stopTime: string = "";
  private taskResult: string = "";
  private mapName: string = "";
  private taskName: string = "";
  private robotName: string = "";
  private startTimeBegin: string = "";
  private stopTimeBegin: string = "";
  private currentDate: Date = new Date();
  private minDate: Date = new Date(2000, 0, 1);
  private maxDate: Date = new Date(2035, 10, 1);
  private scroll: number = 0;
  private isPause: boolean = false;
  private fiterStatus: textStatus[] = [];
  private fiterMapName: textStatus[] = [];
  private fiterWorkName: textStatus[] = [];
  private fiterRobotLabel: textStatus[] = [];
  private rlength: number = 0;
  private isLock: boolean = false;
  private selecedRobots: string = '';
  private selecedMaps: string = '';
  private setList: filterList[] = [
    {
      all: false,
      title: this.$t("dealStatus").toString(),
      icon: require("@/assets/img/cleaning.png"),
      list: [
        { isSelect: false, text: this.$t("dealOK").toString(), value: "1" },
        { isSelect: false, text: this.$t("trapped").toString(), value: "-16" },
        { isSelect: false, text: this.$t("location_lost").toString(), value: "-18" },
        { isSelect: false, text: this.$t("docking_failed").toString(), value: "-17" },    
        { isSelect: false, text: this.$t("dealCancel").toString(), value: "2" },
        { isSelect: false, text: this.$t("imu_data_exception").toString(), value: "-6" },                                                                                                                                                                                                     
        { isSelect: false, text: this.$t("dealError").toString(), value: "3" },
        { isSelect: false, text: this.$t("insufficient_water").toString() , value: "-1"},
        { isSelect: false, text: this.$t("sewage_tank_full").toString(), value: "-2" },
        { isSelect: false, text: this.$t("low_charge").toString(), value: "-3" },
        { isSelect: false, text: this.$t("abnormal_battery_communication").toString(), value: "-4" },
        { isSelect: false, text: this.$t("ultrasonic_anomaly").toString(), value: "-5" },
        { isSelect: false, text: this.$t("serial_communication_exception").toString(), value: "-7" },
        { isSelect: false, text: this.$t("lidar_anomaly").toString(), value: "-8" },
        { isSelect: false, text: this.$t("abnormal_depth_camera").toString(), value: "-10" },
        { isSelect: false, text: this.$t("left_drive_exception").toString(), value: "-11" },
        { isSelect: false, text: this.$t("right_drive_exception").toString(), value: "-12" },
        { isSelect: false, text: this.$t("multiple_component_exceptions").toString(), value: "-13" },
        { isSelect: false, text: this.$t("fall_risk").toString(), value: "-15" },
        { isSelect: false, text: this.$t("manual_charge").toString(), value: "-19" },
        { isSelect: false, text: this.$t("emergency_stop").toString(), value: "-20" },
        { isSelect: false, text: this.$t("collision_trigger").toString(), value: "-21" },
        { isSelect: false, text: this.$t("map_loading_cexception").toString(), value: "-23" },
        { isSelect: false, text: this.$t("robot_id_exception").toString(), value: "-24" },
        { isSelect: false, text: this.$t("loading_task_exception").toString(), value: "-25" },
        { isSelect: false, text: this.$t("task_occupation").toString(), value: "-26" },
        { isSelect: false, text: this.$t("no_valid_tasks_found").toString(), value: "-27" },
        { isSelect: false, text: this.$t("map_selection_error").toString(), value: "-28" },
        { isSelect: false, text: this.$t("manual_mode").toString(), value: "-29" },
        { isSelect: false, text: this.$t("depth_camera_0_abnormal").toString(), value: "-30" },
        { isSelect: false, text: this.$t("depth_camera_1_abnormal").toString(), value: "-31" },
        { isSelect: false, text: this.$t("depth_camera_2_abnormal").toString(), value: "-32" },
        { isSelect: false, text: this.$t("depth_camera_3_abnormal").toString(), value: "-33" },
        { isSelect: false, text: this.$t("failed_to_exit_the_station").toString(), value: "-34" },
        { isSelect: false, text: this.$t("station_location_error").toString(), value: "-35" },
        { isSelect: false, text: this.$t("location_failed").toString(), value: "-36" }
      ],
      searchText: ''
    },
    {
      all: false,
      title: this.$t("robotLabel").toString(),
      icon: require("@/assets/img/robot1.png"),
      list: [],
      searchText: ''
    },
    {
      all: false,
      title: this.$t("mapName").toString(),
      icon: require("@/assets/img/location2.png"),
      list: [],
      searchText: ''
    },
    {
      all: false,
      title: this.$t("workName").toString(),
      icon: require("@/assets/img/workName2.png"),
      list: [],
      searchText: ''
    },
  ];

  private req: GetWorkListReq = {
    page: 1,
    limit: this.limit,
    companyType: "0"
  };

  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  private checkSearchText(item: filterList, index: number) {
    this.rlength = 0;
    switch(index) {
      case 0:
        if (item.searchText == "") {
          this.setList[index].list = this.fiterStatus;
        } else {       
          this.setList[index].list = this.filterList(this.fiterStatus, item.searchText);
        }
        break;
      case 1:
        if (item.searchText == "") {
          this.setList[index].list = this.fiterRobotLabel;
        } else {
          this.setList[index].list = this.filterList(this.fiterRobotLabel, item.searchText);
        }
        break;
      case 2:
        if (item.searchText == "") {
          this.setList[index].list = this.fiterMapName;
        } else {
          this.setList[index].list = this.filterList(this.fiterMapName, item.searchText);
        }
        break;
      case 3:
        if (item.searchText == "") {
          this.setList[index].list = this.fiterWorkName;
        } else {
          this.setList[index].list = this.filterList(this.fiterWorkName, item.searchText);
        }
        break;
    }
  }
   private filterList(list: textStatus[], text: string) {
    return list.filter((item: textStatus) => this.check(item, text));
  }

  private check(nameText: textStatus, text: string) {
    if (
      (nameText.text.toLowerCase().indexOf(text.toLowerCase()) > -1 || nameText.value.toLowerCase().indexOf(text.toLowerCase()) > -1) &&
      this.rlength < 40
    ) {
      this.rlength++;
      return true;
    } else {
      return false;
    }
  }
  private focusChange (item: filterList, type: string) {
    if(type == 'focus') {
      item.all = true
    } else if(type == 'blur') {
      // item.all = false
    }
  }
  handleScroll() {
    if (!this.isPause) {
      const $content = document.querySelector(".workBox");
      const scrollTop = $content ? $content.scrollTop : 0;
      this.scroll = scrollTop;
      console.log("handleScroll this.scrollxxd:" + this.scroll + " this.isPause:" + this.isPause + " window.pageYOffset:" + window.pageYOffset + " document.documentElement.scrollTop :" + document.documentElement.scrollTop + "document.body.scrollTop:" + document.body.scrollTop );
    }
  }
  activated () {
    this.isPause = false
    let refresh = localStorage.getItem("refresh");
    // console.log("work activated refresh:" + refresh +  " robotName:" + this.$route.params.robotName);
    if (refresh != null) {
      this.workList = [];
      localStorage.removeItem("refresh");
      this.robotName = this.$route.params.robotName;
      this.req.robotName = this.robotName;
      this.onRefresh();
    } else {
      const $content = document.querySelector(".workBox");
      console.log("this.scroll:" + this.scroll + " $content:" + $content)
      if (this.scroll && $content) {
        console.log("this.scroll:" + this.scroll)
        $content.scrollTop = this.scroll;
      }
    }
  }
  deactivated() {
    this.isPause = true;
    console.log('Component work deactivated');
  }
  async created() {
    console.log("work created");
    this.robotName = this.$route.params.robotName;
    this.req.robotName = this.robotName;
    this.initData();
    // console.log("work created robotName：" + this.req.robotName );
  }
  private async initData () {
    const [robotRes, taskRes, mapRes]: Array<any> = await Promise.all([
      getRobotList({ page: 1, limit: 1000 }),
      getTaskNames({robotIds: this.selecedRobots, mapNames: this.selecedMaps}),
      getMapNames({robotIds: this.selecedRobots}),
    ]);
    this.setList[1].list = [];
    this.setList[2].list = [];
    this.setList[3].list = [];
    if (robotRes.code == 0) {
      for (let index = 0; index < robotRes.page.list.length; index++) {
        const element = robotRes.page.list[index];
        this.setList[1].list.push({
          isSelect: false,
          text: element.robotEntity.name,
          value: element.robotEntity.robotId
        });
      }
    }
    if (mapRes.code == 0) {
      for (let index = 0; index < mapRes.data.length; index++) {
        const element = mapRes.data[index];
        this.setList[2].list.push({
          isSelect: false,
          text: element,
          value: "0"
        });
      }
    }
    
    if (taskRes.code == 0) {
      for (let index = 0; index < taskRes.data.length; index++) {
        const element = taskRes.data[index];
        this.setList[3].list.push({
          isSelect: false,
          text: element,
          value: "0"
        });
      }
    }
    this.fiterStatus = this.setList[0].list
    this.fiterRobotLabel = this.setList[1].list
    this.fiterMapName = this.setList[2].list
    this.fiterWorkName = this.setList[3].list
    
    if (this.robotName != undefined) {
      for (let index = 0; index < this.fiterRobotLabel.length; index++) { 
        const element = this.fiterRobotLabel[index];
        console.log("work created element.text:" + element.isSelect + " this.robotName:" + this.robotName);
        if(element.text === this.robotName) {
          console.log("work created isSelect:" + element.isSelect);
          this.fiterRobotLabel[index].isSelect = true
        }
      }
    }
  }
  private async sysFitleNames (type: number) {
    console.log('selectItem type' + type)
    if (type == 1) {
      this.selecedRobots = "";
      for (let index = 0; index < this.fiterRobotLabel.length; index++) { 
        const element = this.fiterRobotLabel[index];
        if (element.isSelect) {
          this.selecedRobots += element.value + ",";
        }
      }
      if (this.selecedRobots != "") {
        this.selecedRobots= this.selecedRobots.substring(
          0,
          this.selecedRobots.length - 1
        );
      }
      const [taskRes, mapRes]: Array<any> = await Promise.all([
        getTaskNames({robotIds: this.selecedRobots, mapNames: ''}),
        getMapNames({robotIds: this.selecedRobots}),
      ]);
      this.setList[2].list = [];
      this.setList[3].list = [];
      if (mapRes.code == 0) {
        for (let index = 0; index < mapRes.data.length; index++) {
          const element = mapRes.data[index];
          this.setList[2].list.push({
            isSelect: false,
            text: element,
            value: "0"
          });
        }
      }
      
      if (taskRes.code == 0) {
        for (let index = 0; index < taskRes.data.length; index++) {
          const element = taskRes.data[index];
          this.setList[3].list.push({
            isSelect: false,
            text: element,
            value: "0"
          });
        }
      }
      this.fiterMapName = this.setList[2].list
      this.fiterWorkName = this.setList[3].list
    } else if (type == 2) {
      this.selecedMaps = "";
      for (let index = 0; index < this.fiterMapName.length; index++) {
        const element = this.fiterMapName[index];
        if (element.isSelect) {
          this.selecedMaps += element.text + ",";
        }
      }
      const [taskRes]: Array<any> = await Promise.all([
        getTaskNames({robotIds: this.selecedRobots, mapNames: this.selecedMaps}),
      ]);
      this.setList[3].list = [];
      if (taskRes.code == 0) {
        for (let index = 0; index < taskRes.data.length; index++) {
          const element = taskRes.data[index];
          this.setList[3].list.push({
            isSelect: false,
            text: element,
            value: "0"
          });
        }
      }
      this.fiterWorkName = this.setList[3].list
    }
  }
  private selectItemAll(list: filterList) {
    list.all = !list.all;
  }

  private selectItem(item: textStatus, index: number) {
    console.log('selectItem item：' + item + ' index:' + index)
    item.isSelect = !item.isSelect;
    this.sysFitleNames(index)
  }

  private onRefresh() {
    console.log("onRefresh");
    this.finished = false;
    this.req.page = 1;
    this.loading = true;  
    this.onLoad();
  }

  private onLoad() {
    console.log("onLoad");
    this.getWorkList();
    this.initData();
  }
  private async getWorkList() {
    let res: CommonListRes<Page<Work>> = await getWorkList(this.req);
    if (this.refreshing) {
      this.workList = [];
      this.refreshing = false;
    }
    if (res.code == 0) {
      this.workList = this.workList.concat(res.page.list);
      this.loading = false;
      if (res.page.totalPage <= this.req.page) {
        this.finished = true;
      } else {
        this.req.page++;
      }
    } else {
      this.loading = false;
      this.finished = true;
    }
  }

  private openFilter() {
    for (let index = 0; index < this.setList.length; index++) {
      const element = this.setList[index];
      element.all = false;
    }
    this.show = true;
  }

  private clickItemEvent(robotId: string, name: string, id: string) {
    this.isPause = true
    this.$router.push({
      path: "/workDetail",
      name: "WorkDetail",
      params: {robotName: name, robotId: robotId, id: id},
    });
  }

  private clickStartTime() {
    this.type = 0;
    this.showDateTime = true;
  }

  private clickStopTime() {
    this.type = 1;
    this.showDateTime = true;
  }

  private confirmDateTime() {
    this.showDateTime = false;
    if (this.type == 0) {
      this.startTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else {
      this.stopTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    }
  }

  private cancelDateTime() {
    this.showDateTime = false;
  }

  private clickConfirm() {
    this.setReq();
    this.show = false;
    this.refreshing = true;
    this.onRefresh();
  }

  private clickReset() {
    for (let index = 0; index < this.setList.length; index++) {
      const element = this.setList[index];
      element.searchText = ""
      for (let index1 = 0; index1 < element.list.length; index1++) {
        const element1 = element.list[index1];
        element1.isSelect = false;
      }
      this.checkSearchText(element, index);
    }
    this.startTime = "";
    this.stopTime = "";
    this.setReq();
    this.show = false;
    this.refreshing = true;
    this.onRefresh();
  }

  private setReq() {
    this.req = {
      page: 1,
      limit: this.limit,
      companyType: "0"
    };
    this.taskResult = "";
    for (let index = 0; index < this.fiterStatus.length; index++) {
      const element = this.fiterStatus[index];
      // if (element.isSelect) {
      //   this.taskResult += index + 1 + ",";
      // }
      if (element.isSelect) {
        this.taskResult += element.value + ",";
      }
    }
    this.mapName = "";
    for (let index = 0; index < this.fiterMapName.length; index++) {
      const element = this.fiterMapName[index];
      if (element.isSelect) {
        this.mapName += element.text + ",";
      }
    }
    this.taskName = "";
    for (let index = 0; index < this.fiterWorkName.length; index++) {
      const element = this.fiterWorkName[index];
      if (element.isSelect) {
        this.taskName += element.text + ",";
      }
    }
    this.robotName = "";
    for (let index = 0; index < this.fiterRobotLabel.length; index++) { 
      const element = this.fiterRobotLabel[index];
      if (element.isSelect) {
        this.robotName += element.text + ",";
      }
    }
    if (this.taskResult != "") {
      this.req.taskResult = this.taskResult.substring(
        0,
        this.taskResult.length - 1
      );
    }
    if (this.mapName != "") {
      this.req.mapName = this.mapName.substring(0, this.mapName.length - 1);
    }
    if (this.taskName != "") {
      this.req.taskName = this.taskName.substring(0, this.taskName.length - 1);
    }
    if (this.robotName != "") {
      this.req.robotName = this.robotName.substring(
        0,
        this.robotName.length - 1
      );
    }
    if (this.startTime != "") {
      this.req.startTimeBegin = this.startTime;
    }
    if (this.stopTime != "") {
      this.req.startTimeEnd = this.stopTime;
    }
  }

  private async confirmExcel () {
    if(this.isLock) {
      console.log("confirmExcel isLock")
      return
    }
    setTimeout(() => {
      this.isLock = false;
    }, 8000);
    this.isLock = true
    this.showDialog = true;
  }
  private async exportReprort() { 
    this.showDialog = false;
    console.log("confirmExcel do export Task")
    //Toast(globalData.exportFileUrl + this.$t("exportTaskStart").toString());
    if(location.host == globalData.yjhUrl){
        this.req.companyType = "1"
    }
    let res: CommonRes = await exportTask(this.req);
    if (res.code == 0) {
      //Toast(this.$t("exportTaskSuccess").toString());
      console.log("globalData.exportFileUrl", globalData.exportFileUrl);
      Toast(this.$t("exporting_report").toString())
      return false
    }
  }
  private cancelEvent() {
    this.showDialog = false;
    this.isLock = false;
  }
}
